import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
    <Layout>
        <div className="layout-padding-top"></div>
        <div className="error-page-wrapper d-flex align-items-center">
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col xl={8} className="text-center">
                        <h1 className="primary-dark-heading">Something went wrong!</h1>
                        <p className="electra-para">We can't find the page you were looking for. This is maybe because it has been removed, we've changed its name or it is temporarily unavailable.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <Link to="/contact" className="button button-primary">Contact us</Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to="/" className="button button-primary-outline">Homepage</Link>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
